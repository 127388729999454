import React from 'react';

const Guides: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Guides</h1>
      <p>Learn how to build PCs and get advice on component selection.</p>
      {/* Add guides and tutorials here */}
    </div>
  );
};

export default Guides;