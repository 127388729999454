import React from 'react';

const Products: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Products</h1>
      <p>Browse and compare PC components and peripherals.</p>
      {/* Add product browsing functionality here */}
    </div>
  );
};

export default Products;