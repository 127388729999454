import React from 'react';
import PartList from '../components/HomePage/PartList';

const HomePage: React.FC = () => {
  return (
    <main className="container mx-auto px-4 py-8">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">
          Pick Parts. Build Your PC. Compare and Share.
        </h1>
        <p className="text-xl mb-6">
          We provide part selection, pricing, and compatibility guidance for
          do-it-yourself computer builders.
        </p>
        <button className="btn-blue">Start Your Build</button>
      </div>
      <PartList />
    </main>
  );
};

export default HomePage;