import React, { useRef, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

const Crosshair: React.FC = () => {
  const dotRef = useRef<THREE.Mesh>(null);
  const { camera } = useThree();

  useEffect(() => {
    if (dotRef.current) {
      // Position the dot slightly in front of the camera
      dotRef.current.position.set(0, 0, -0.1);

      // Add the dot to the camera
      camera.add(dotRef.current);

      // Ensure the dot is always rendered on top
      dotRef.current.renderOrder = 999;

      return () => {
        camera.remove(dotRef.current!);
      };
    }
  }, [camera]);

  return (
    <mesh ref={dotRef}>
      <circleGeometry args={[0.0005, 32]} />
      <meshBasicMaterial color="white" />
    </mesh>
  );
};

export default Crosshair;