import React from 'react';
import { Link } from 'react-router-dom';
import { NAV_ITEMS } from '../../services/constants';

const Navigation: React.FC = () => {
  return (
    <nav>
      <ul className="flex space-x-4">
        {NAV_ITEMS.map((item) => (
          <li key={item.name}>
            <Link to={item.href} className="nav-link flex items-center">
              <item.icon className="w-4 h-4 mr-1" />
              <span>{item.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;