import React, { useState, useEffect } from 'react';
import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import { usePCBuilderState } from '../../hooks/usePCBuilderState';
import PCBuilderControls from '../PCBuilderControls/PCBuilderControls';
import RealisticScene from './RealisticScene';
import LowEndScene from './LowEndScene';
import { Maximize2, Minimize2, RotateCcw, SwitchCamera } from 'lucide-react';

const PCBuilderScene: React.FC = () => {
  const {
    components, setComponents,
    isLoading, setIsLoading,
    selectedAsset, setSelectedAsset,
    lightingOptions, updateLightingOption,
    caseOptions, updateCaseOption,
    materialType, setMaterialType,
    lightPresets, saveLightPreset, loadLightPreset,
    pc, 
    setPc,
    addPart,
    removePart,
    checkCompatibility,
  } = usePCBuilderState();

  const [currentScene, setCurrentScene] = useState<'realistic' | 'lowEnd'>('lowEnd');
  const [resetCamera, setResetCamera] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPhone, setIsPhone] = useState(false);

  useEffect(() => {
    const checkIfPhone = () => {
      setIsPhone(window.innerWidth <= 768);
    };

    checkIfPhone();
    window.addEventListener('resize', checkIfPhone);

    return () => window.removeEventListener('resize', checkIfPhone);
  }, []);

  const handleSelectComponent = (category: string, modelName: string) => {
    setSelectedAsset(`${category}/${modelName}`);
  };

  const handleDeleteComponent = (id: string, partId: string) => {
    setComponents(prevComponents => prevComponents.filter(c => c.id !== partId));
    removePart(id)
  };

  const handleUpdateComponent = (id: string, newPosition: THREE.Vector3, newRotation: THREE.Euler) => {
    setComponents(prevComponents => 
      prevComponents.map(c => 
        c.id === id ? { ...c, position: newPosition, rotation: newRotation } : c
      )
    );
  };

  const toggleScene = () => {
    setCurrentScene(prev => prev === 'realistic' ? 'lowEnd' : 'realistic');
    setResetCamera(true);
  };

  const handleResetCamera = () => {
    setResetCamera(true);
  };

  const toggleFullscreen = () => {
    const container = document.querySelector('.pc-builder-container') as HTMLElement;
    if (container) {
      container.classList.toggle('h-full');
      container.classList.toggle('w-full');
      container.classList.toggle('h-[70%]');
      container.classList.toggle('w-[70%]');
      setIsFullscreen(!isFullscreen);
    }
  };

  return (
    <div className={`flex flex-col h-screen w-screen ${isPhone ? 'overflow-auto' : ''}`}>
      <div className={`pc-builder-container flex ${isPhone ? 'flex-col' : 'flex-row'} h-[70%] w-[70%] m-auto`}>
        <div className={`${isPhone ? 'h-1/2' : 'flex-none w-2/5 h-full'} overflow-auto z-20`}>
          <PCBuilderControls
            lightingOptions={lightingOptions}
            updateLightingOption={updateLightingOption}
            materialType={materialType}
            setMaterialType={setMaterialType}
            saveLightPreset={saveLightPreset}
            loadLightPreset={loadLightPreset}
            lightPresets={lightPresets}
            pc={pc}
            setPc={setPc}
            components={components}
            setComponents={setComponents}
            addPart={addPart}
            removePart={handleDeleteComponent}
            checkCompatibility={checkCompatibility}
            onClose={() => {}}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setSelectedAsset={setSelectedAsset}
          />
        </div>
        
        <div className={`${isPhone ? 'h-1/2' : 'flex-none w-3/5 h-full'} overflow-hidden relative`}>
          <div className="absolute top-2.5 left-1/2 transform -translate-x-1/2 z-10 flex gap-2.5 text-white">
            <button onClick={toggleScene}>
              <SwitchCamera size={24} />
            </button>
            <button onClick={handleResetCamera}>
              <RotateCcw size={24} />
            </button>
            <button onClick={toggleFullscreen}>
              {isFullscreen ? <Minimize2 size={24} /> : <Maximize2 size={24} />}
            </button>
          </div>
          <Canvas className="absolute inset-0 w-full h-full">
            {currentScene === 'realistic' ? (
              <RealisticScene 
                components={components}
                onUpdateComponent={handleUpdateComponent}
                lightingOptions={lightingOptions}
                materialType={materialType}
                caseOptions={caseOptions}
                pc={pc}
                setPc={setPc}
                highResEnvPath="/textures/sunset_jhbcentral_8k_cropped.png"
                lowResEnvPath="/textures/meadow_2k.hdr"
                rotation={[0, 0, 0]}
                resetCamera={resetCamera}
                onResetComplete={() => setResetCamera(false)}
              />
            ) : (
              <LowEndScene 
                components={components}
                onUpdateComponent={handleUpdateComponent}
                materialType={materialType}
                lightingOptions={lightingOptions}
                pc={pc}
                setPc={setPc}
                resetCamera={resetCamera}
                onResetComplete={() => setResetCamera(false)}
              />
            )}
          </Canvas>
        </div>
      </div>
    </div>
  );
};

export default PCBuilderScene;