import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { SimplifyModifier } from 'three/examples/jsm/modifiers/SimplifyModifier.js';
import { assetLoader } from './assetLoader';
import { PCComponent, PC } from '../types/types';

const movePivot = (object: THREE.Object3D, isCase: boolean) => {
  const box = new THREE.Box3().setFromObject(object);
  const originalSize = box.getSize(new THREE.Vector3());
  
  // Create adjusted size for case
  const size = isCase 
    ? new THREE.Vector3(originalSize.z, originalSize.y, originalSize.x)
    : originalSize;

  const offset = new THREE.Vector3(
    box.min.x,
    box.max.y,
    isCase ? box.max.z : box.min.z
  );

  object.children.forEach((child) => {
    child.position.sub(offset);
  });

  object.position.add(offset);

  box.setFromObject(object);

  return { size, box };
};

function reduceTriangles(object: THREE.Group, targetReduction: number): THREE.Group {
  const modifier = new SimplifyModifier();

  function simplifyGeometry(geometry: THREE.BufferGeometry): THREE.BufferGeometry {
    if (!geometry.index) {
      //console.warn('Skipping simplification for non-indexed geometry');
      return geometry;
    }

    const originalCount = geometry.index.count / 3;
    const targetCount = Math.max(4, Math.floor(originalCount * (1 - targetReduction)));

    try {
      const simplified = modifier.modify(geometry, targetCount);
      //console.log(`Reduced triangles: ${originalCount} -> ${simplified.index ? simplified.index.count / 3 : simplified.attributes.position.count / 3}`);
      return simplified;
    } catch (error) {
      //console.warn('Error simplifying geometry:', error);
      return geometry; // Return original geometry if simplification fails
    }
  }

  function processObject(obj: THREE.Object3D) {
    if (obj instanceof THREE.Mesh && obj.geometry instanceof THREE.BufferGeometry) {
      const originalGeometry = obj.geometry;
      const simplifiedGeometry = simplifyGeometry(originalGeometry);
      
      if (simplifiedGeometry !== originalGeometry) {
        obj.geometry.dispose(); // Clean up the old geometry
        obj.geometry = simplifiedGeometry;
      }
    }

    obj.children.forEach(processObject);
  }

  processObject(object);
  return object;
}

export const handleFetchChunks = async (
  selectedAsset: string,
  setComponents: React.Dispatch<React.SetStateAction<PCComponent[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedAsset: React.Dispatch<React.SetStateAction<string | null>>,
  pc: PC,
  setPc: React.Dispatch<React.SetStateAction<PC>>,
  scale: number
) => {
  setIsLoading(true);
  try {
    const gltfData = await assetLoader.loadAsset(selectedAsset);
    //console.log('GLTF data loaded:', gltfData);
    
    const loader = new GLTFLoader();
    loader.setMeshoptDecoder(MeshoptDecoder);
    
    loader.parse(gltfData, '', (gltf) => {
      //console.log('GLTF parsed successfully:', gltf);
      const scene = gltf.scene as THREE.Group;

      const reducedScene = reduceTriangles(scene, 0.8);
      

      const [category, modelName] = selectedAsset.split('/');

      const isCase = category.toLowerCase() === 'case';
      let { size: componentDimensions, box } = movePivot(gltf.scene, isCase);

      reducedScene.scale.set(scale, scale, scale);

      componentDimensions = componentDimensions.multiplyScalar(scale);

      const motherboardPosition = pc.case.motherboard.position;
      const motherboardDimensions = pc.case.motherboard.dimensions;
      const cpuPosition = pc.case.motherboard.cpu.position;
      const cpuDimensions = pc.case.motherboard.cpu.dimensions;
      const componentPositions = pc.case.motherboard.componentPositions;

      let newPosition: THREE.Vector3;
      let newRotation: THREE.Euler;
      if (isCase || !pc.case.obj) {
        const zDimension = isCase ? componentDimensions.z : 
          (['psu', 'storage', 'ssd'].includes(category.toLowerCase())) ? componentDimensions.z :
          (['memory', 'cpu', 'cooler', 'motherboard', 'm2'].includes(category.toLowerCase())) ? componentDimensions.y :
          componentDimensions.z;

        newPosition = new THREE.Vector3(
          -0.15 - componentDimensions.x / 2,
          isCase || ['psu', 'storage', 'ssd'].includes(category.toLowerCase()) ? 0.93 + componentDimensions.y : 0.94,
          category.toLowerCase() === 'gpu' ? -7.755 + zDimension / 2 : -7.755 - zDimension / 2
        );
        newRotation = new THREE.Euler(
          isCase ? 0 : ((['psu', 'storage', 'ssd'].includes(category.toLowerCase())) ? 0 : ((['memory', 'cpu', 'cooler', 'motherboard', 'm2'].includes(category.toLowerCase())) ? -Math.PI / 2 : Math.PI)),
          isCase ? -Math.PI / 2 : 0,
          0
        );
        
        if (isCase) {
          setPc(prevPc => ({
            ...prevPc,
            case: {
              ...prevPc.case,
              dimensions: componentDimensions,
              maxGpuLength: componentDimensions.z * 0.8,
              maxCoolerHeight: componentDimensions.x * 0.8,
            }
          }));
        }
      } else {
        // Position other components based on their type and the case dimensions
        newRotation = new THREE.Euler(0, 0, 0);
        switch (category.toLowerCase()) {
          case 'motherboard':
            newPosition = new THREE.Vector3(
              pc.case.position.x + 0.002  * scale,
              pc.case.position.y - (pc.case.dimensions?.y / 10),
              pc.case.position.z + 0.035 * scale
            );
            break;
          case 'cpu':
            newPosition = new THREE.Vector3(
              motherboardPosition.x + (motherboardDimensions.x * componentPositions['cpu'].x),
              motherboardPosition.y - (motherboardDimensions.y * componentPositions['cpu'].y),
              motherboardPosition.z + 0.007  * scale
            );
            break;
          case 'cooler':
            newPosition = new THREE.Vector3(
              cpuPosition.x + (cpuDimensions?.x / 2) - (componentDimensions.x / 2),
              cpuPosition.y - (cpuDimensions?.y / 2) + (componentDimensions.y / 2),
              cpuPosition.z + 0.004  * scale
            );
            break;
          case 'gpu':
            newPosition = new THREE.Vector3(
              motherboardPosition.x + 0.0064,
              motherboardPosition.y - (motherboardDimensions.y * componentPositions['gpu'].y) + 0.003,
              motherboardPosition.z - 0.005  * scale
            );
            break;
          case 'memory':
            newPosition = new THREE.Vector3(
              motherboardPosition.x + (motherboardDimensions.x * componentPositions['memory'].x) + 0.001,
              motherboardPosition.y - (motherboardDimensions.y * componentPositions['memory'].y) + 0.005,
              motherboardPosition.z + 0.01  * scale
            );
            break;
          case 'm2':
            newPosition = new THREE.Vector3(
              motherboardPosition.x + (motherboardDimensions?.x / 4),
              motherboardPosition.y - (motherboardDimensions?.y / 1.8),
              motherboardPosition.z + 0.01  * scale
            );
            break;
          case 'psu':
            newPosition = new THREE.Vector3(
              pc.case.position.x + 0.002  * scale,
              pc.case.position.y - (pc.case.dimensions?.y - componentDimensions.y) + 0.032  * scale,
              pc.case.position.z + (pc.case.dimensions?.x / 12)
            );
            break;
          default:
            // Default position for other components
            newPosition = new THREE.Vector3(
              pc.case.position.x,
              pc.case.position.y + (motherboardDimensions?.y / 1.5),
              pc.case.position.z
            );
        }
      }
      const newComponent: PCComponent = {
        id: `${category}-${modelName}-${Date.now()}`,
        category,
        modelName,
        position: newPosition,
        rotation: newRotation,
        obj: reducedScene,
        present: true,
        dimensions: componentDimensions,
      };
      if (newComponent.obj) {
        //console.log('Part obj exists');
        if (newComponent.category === 'case' && newComponent.obj) {
          if (!pc.case.obj) {
            pc.case.obj = new THREE.Group();
            newComponent.parentId = null; // Top-level component, no parent
          }
        } else if (newComponent.category === 'motherboard') {
          if (!pc.case.motherboard.obj) {
            // Create a group for the motherboard if it doesn't exist
            pc.case.motherboard.obj = new THREE.Group();
            if (pc.case.obj) {
              pc.case.obj.add(pc.case.motherboard.obj);
            }
            pc.case.motherboard.obj.add(newComponent.obj);
            newComponent.parentId = pc.case.id; // Set parentId to the case id
          }
        } else if (newComponent.category === 'psu') {
          if (pc.case.obj) {
            pc.case.obj.add(newComponent.obj);
            newComponent.parentId = pc.case.id;
          }
          setPc((prevPc) => ({
            ...prevPc,
            case: {
              ...prevPc.case,
              psu: newComponent,
            },
          }));
        } else if (pc.case.motherboard.obj) {
          // Add other components to the motherboard group
          pc.case.motherboard.obj.add(newComponent.obj);
          newComponent.parentId = pc.case.motherboard.id; // Set parentId to the motherboard id
        }
      } else {
        console.log('Part obj does not exist');
      }
      
      
      setComponents(prevComponents => [...prevComponents, newComponent]);

      // Update pc state with the new component
      setPc(prevPc => {
        const updateComponent = (obj: any, path: string[]): any => {
          if (path.length === 0) {
            return {
              ...obj,
              ...newComponent,
              present: true,
              dimensions: componentDimensions,
            };
          }
          
          const [current, ...rest] = path;
          return {
            ...obj,
            [current]: updateComponent(obj[current] || {}, rest),
          };
        };
      
        let updatedPc = { ...prevPc };
        const categoryPath = category.toLowerCase().split('.');
      
        if (category.toLowerCase() === 'case') {
          updatedPc.case = updateComponent(updatedPc.case, []);
          updatedPc.case.maxGpuLength = componentDimensions.x * 0.8;
          updatedPc.case.maxCoolerHeight = componentDimensions.z * 0.8;
        }
        else if (category.toLowerCase() === 'motherboard')
        {
          updatedPc = updateComponent(updatedPc, ['case', ...categoryPath]);
        }
        else {
          updatedPc = updateComponent(updatedPc, ['case', 'motherboard', ...categoryPath]);
        }
      
        return updatedPc;
      });
      //console.log(pc.case);
      setIsLoading(false);
      setSelectedAsset(null);
    }, (error) => {
      console.error('Error parsing model:', error);
      setIsLoading(false);
    });
  } catch (error) {
    console.error('Error loading asset:', error);
    setIsLoading(false);
  }
};

export const createPositionClamper = (
  roomPosition: THREE.Vector3,
  roomSize: { width: number; depth: number; height: number },
  sceneRotation: THREE.Euler,
  objects: Array<{
    position: THREE.Vector3;
    size: { width: number; depth: number; height: number };
  }>
) => {
  const rotationMatrix = new THREE.Matrix4().makeRotationFromEuler(sceneRotation);
  const inverseRotationMatrix = new THREE.Matrix4().copy(rotationMatrix).invert();

  const roomMin = roomPosition.clone().sub(new THREE.Vector3(-0.2 + roomSize.width/2, -0.2, -1.4 + roomSize.depth/2));
  const roomMax = roomPosition.clone().add(new THREE.Vector3(roomSize.width/2-0.2, roomSize.height-0.2, roomSize.depth/2-0.2));

  const roomCenter = roomMin.clone().add(roomMax).multiplyScalar(0.5);

  return (position: THREE.Vector3 | null) => {
    if (!position) {
      return roomCenter.clone();
    }

    // Undo scene rotation
    const unrotatedPosition = position.clone().applyMatrix4(inverseRotationMatrix);

    // Check if position is far outside the room boundaries
    const isFarOutside = 
      unrotatedPosition.x < roomMin.x - roomSize.width ||
      unrotatedPosition.x > roomMax.x + roomSize.width ||
      unrotatedPosition.y < roomMin.y - roomSize.height ||
      unrotatedPosition.y > roomMax.y + roomSize.height ||
      unrotatedPosition.z < roomMin.z - roomSize.depth ||
      unrotatedPosition.z > roomMax.z + roomSize.depth;

    if (isFarOutside) {
      return roomCenter.clone().applyMatrix4(rotationMatrix);
    }

    // Clamp the position to room boundaries
    unrotatedPosition.clamp(roomMin, roomMax);

    // Check for collisions with objects
    objects.forEach(object => {
      const objectMin = object.position.clone().sub(new THREE.Vector3(object.size.width/2, 0, object.size.depth/2));
      const objectMax = object.position.clone().add(new THREE.Vector3(object.size.width/2, object.size.height, object.size.depth/2));

      if (unrotatedPosition.x > objectMin.x && unrotatedPosition.x < objectMax.x &&
          unrotatedPosition.z > objectMin.z && unrotatedPosition.z < objectMax.z) {
        // Collision detected, adjust position
        const distToMinX = Math.abs(unrotatedPosition.x - objectMin.x);
        const distToMaxX = Math.abs(unrotatedPosition.x - objectMax.x);
        const distToMinZ = Math.abs(unrotatedPosition.z - objectMin.z);
        const distToMaxZ = Math.abs(unrotatedPosition.z - objectMax.z);

        const minDist = Math.min(distToMinX, distToMaxX, distToMinZ, distToMaxZ);

        if (minDist === distToMinX) unrotatedPosition.x = objectMin.x;
        else if (minDist === distToMaxX) unrotatedPosition.x = objectMax.x;
        else if (minDist === distToMinZ) unrotatedPosition.z = objectMin.z;
        else if (minDist === distToMaxZ) unrotatedPosition.z = objectMax.z;
      }
    });

    // Re-apply scene rotation
    return unrotatedPosition.applyMatrix4(rotationMatrix);
  };
};