import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Layout/Header';
import HomePage from './pages';
import Builder from './pages/builder';
import Products from './pages/products';
import Guides from './pages/guides';
import CompletedBuilds from './pages/completed-builds';

const App: React.FC = () => {
  return (
    <Router>
      <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-white">      
        <Header/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/builder" element={<Builder />} />
          <Route path="/products" element={<Products />} />
          <Route path="/guides" element={<Guides />} />
          <Route path="/completed-builds" element={<CompletedBuilds />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;