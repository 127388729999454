import React, { useState } from 'react';
import { INITIAL_PARTS } from '../../services/constants';

const PartList: React.FC = () => {
  const [parts] = useState(INITIAL_PARTS);
  const [view, setView] = useState<'overview' | 'byMerchant'>('overview');

  return (
    <div className="part-list text-white">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">Part List</h2>
        <div>
          <button
            className={`mr-2 ${view === 'overview' ? 'btn-blue' : 'nav-link'}`}
            onClick={() => setView('overview')}
          >
            Overview
          </button>
          <button
            className={view === 'byMerchant' ? 'btn-blue' : 'nav-link'}
            onClick={() => setView('byMerchant')}
          >
            Prices By Merchant
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Component</th>
            <th>Selection</th>
            <th>Base</th>
            <th>Promo</th>
            <th>Shipping</th>
            <th>Tax</th>
            <th>Price</th>
            <th>Where</th>
          </tr>
        </thead>
        <tbody>
          {parts.map((part) => (
            <tr key={part.id}>
              <td>{part.component}</td>
              <td>{part.selection}</td>
              <td>${part.base.toFixed(2)}</td>
              <td>${part.promo.toFixed(2)}</td>
              <td>${part.shipping.toFixed(2)}</td>
              <td>${part.tax.toFixed(2)}</td>
              <td>${part.price.toFixed(2)}</td>
              <td>
                <button className="btn-buy">Buy</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartList;