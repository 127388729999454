import React, { useEffect, useRef, useMemo } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

interface EnvironmentLoaderProps {
  imagePath: string;
  width: number;
  height: number;
  rotation?: number;
  includeLighting?: boolean;
  sphereRadius?: number;
  debug?: boolean;
  verticalScale?: number;
  horizontalScale?: number;
}

const EnvironmentLoader: React.FC<EnvironmentLoaderProps> = ({
  imagePath,
  width,
  height,
  rotation = 0,
  includeLighting = true,
  sphereRadius = 500,
  debug = false,
  verticalScale = 1,
  horizontalScale = 1,
}) => {
  const { scene, gl, camera } = useThree();
  const sphereRef = useRef<THREE.Mesh>(null);
  const textureRef = useRef<THREE.Texture | null>(null);
  const initialRenderRef = useRef(true);

  const { geometry, phiStart, phiLength, thetaStart, thetaLength } = useMemo(() => {
    if (!initialRenderRef.current) return {};

    //console.log('Calculating geometry (once)');
    const fullWidth = 8192;
    const fullHeight = 4096;
    
    const phiStart = 0;
    const phiLength = Math.PI * (height / fullHeight) * verticalScale;
    const thetaStart = 0;
    const thetaLength = 2 * Math.PI * (width / fullWidth) * horizontalScale;

    const geometry = new THREE.SphereGeometry(
      sphereRadius,
      Math.ceil(60 * (width / fullWidth) * horizontalScale),
      Math.ceil(40 * (height / fullHeight) * verticalScale),
      thetaStart,
      thetaLength,
      phiStart,
      phiLength
    );

    return { geometry, phiStart, phiLength, thetaStart, thetaLength };
  }, [width, height, sphereRadius, verticalScale, horizontalScale]);

  useEffect(() => {
    if (!initialRenderRef.current) return;

    //console.log('Initial render: Loading texture');
    const loader = imagePath.toLowerCase().endsWith('.hdr') 
      ? new RGBELoader()
      : new THREE.TextureLoader();

    loader.load(
      imagePath,
      (texture) => {
        //('Texture loaded successfully');
        textureRef.current = texture;
        if (texture instanceof THREE.DataTexture) {
          texture.mapping = THREE.EquirectangularReflectionMapping;
        }

        const pmremGenerator = new THREE.PMREMGenerator(gl);
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;
        pmremGenerator.dispose();

        if (includeLighting) {
          scene.environment = envMap;
        }

        if (sphereRef.current) {
          const material = sphereRef.current.material as THREE.ShaderMaterial;
          material.uniforms.envMap.value = texture;
          material.uniforms.textureDimensions.value.set(width, height);
          material.needsUpdate = true;
        }

        if (camera instanceof THREE.PerspectiveCamera) {
          camera.near = 0.1;
          camera.far = sphereRadius * 2;
          camera.updateProjectionMatrix();
        }
      },
      undefined,
      (error) => console.error('Error loading texture:', error)
    );

    initialRenderRef.current = false;

    return () => {
      //console.log('Cleanup: resetting scene environment');
      scene.environment = null;
    };
  }, [imagePath, width, height, includeLighting, scene, gl, camera, sphereRadius]);

  const fragmentShader = `
    uniform sampler2D envMap;
    uniform vec2 textureDimensions;
    uniform vec2 scale;
    varying vec2 vUv;

    void main() {
      vec2 scaledUv = vec2((1.0 - vUv.x) * scale.x, vUv.y * scale.y);
      gl_FragColor = texture2D(envMap, scaledUv);
    }
  `;

  const vertexShader = `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `;
  //console.log('Rendering EnvironmentLoader');
  if (!geometry) return null;

  return (
    <>
      <mesh ref={sphereRef} rotation={[0, rotation, 0]} geometry={geometry}>
        <shaderMaterial
          side={THREE.BackSide}
          fragmentShader={fragmentShader}
          vertexShader={vertexShader}
          uniforms={{
            envMap: { value: textureRef.current },
            textureDimensions: { value: new THREE.Vector2(width, height) },
            scale: { value: new THREE.Vector2(1 / horizontalScale, 1 / verticalScale) },
          }}
        />
      </mesh>
      {debug && (
        <>
          <mesh rotation={[0, rotation, 0]} geometry={geometry}>
            <meshBasicMaterial color="red" wireframe={true} side={THREE.BackSide} />
          </mesh>
          <axesHelper args={[sphereRadius]} />
        </>
      )}
    </>
  );
};

export default EnvironmentLoader;