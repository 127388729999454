import React, { useState } from 'react';
import { UserPlus, LogIn } from 'lucide-react';
import Button from '../common/Button';
import { COUNTRIES } from '../../services/constants';
import ThemeToggle from './ThemeToggle';

const UserActions: React.FC = () => {
  const [country, setCountry] = useState(COUNTRIES[0]);

  return (
    <div className="flex items-center space-x-4 text-white">
      <Button variant="ghost">
        <LogIn className="w-4 h-4 mr-1" />
        Log In
      </Button>
      <Button variant="ghost">
        <UserPlus className="w-4 h-4 mr-1" />
        Register
      </Button>
      <select
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        className="bg-gray-800 text-white border-none rounded-md"
      >
        {COUNTRIES.map((c) => (
          <option key={c} value={c} className="bg-gray-800 text-white ">
            {c}
          </option>
        ))}
      </select>
      <ThemeToggle />
    </div>
  );
};

export default UserActions;