import React from 'react';
import * as THREE from 'three';
import styles from '../PCBuilderControls.module.css';
import { LightingOptions } from '../../../types/types';

interface LightingSettingsProps {
  lightingOptions: LightingOptions;
  updateLightingOption: (key: keyof LightingOptions, value: any) => void;
}

const LightingSettings: React.FC<LightingSettingsProps> = ({ lightingOptions, updateLightingOption }) => {
  return (
    <div className={styles.section}>
      <h3 className={styles.sectionTitle}>Stellar Illumination</h3>
      {/* Ambient Light controls */}
      <div className={styles.controlGroup}>
        <label className={styles.label}>Ambient Light Intensity:</label>
        <input 
          type="range" 
          min="0" 
          max="20" 
          step="1"
          value={lightingOptions.ambientLightIntensity} 
          onChange={(e) => updateLightingOption('ambientLightIntensity', parseFloat(e.target.value))}
          className={styles.slider}
        />
        <span className={styles.value}>{lightingOptions.ambientLightIntensity.toFixed(2)}</span>
      </div>
      <div className={styles.controlGroup}>
        <label className={styles.label}>Ambient Light Color:</label>
        <input 
          type="color" 
          value={lightingOptions.ambientLightColor} 
          onChange={(e) => updateLightingOption('ambientLightColor', e.target.value)}
          className={styles.colorPicker}
        />
      </div>
      {/* Directional Light controls */}
      <div className={styles.controlGroup}>
        <label className={styles.label}>Directional Light Intensity:</label>
        <input 
          type="range" 
          min="0" 
          max="20" 
          step="1"
          value={lightingOptions.directionalLightIntensity} 
          onChange={(e) => updateLightingOption('directionalLightIntensity', parseFloat(e.target.value))}
          className={styles.slider}
        />
        <span className={styles.value}>{lightingOptions.directionalLightIntensity.toFixed(2)}</span>
      </div>
      <div className={styles.controlGroup}>
        <label className={styles.label}>Directional Light Color:</label>
        <input 
          type="color" 
          value={lightingOptions.directionalLightColor} 
          onChange={(e) => updateLightingOption('directionalLightColor', e.target.value)}
          className={styles.colorPicker}
        />
      </div>
      <div className={styles.controlGroup}>
        <label className={styles.label}>Directional Light Position:</label>
        {['x', 'y', 'z'].map((axis) => (
          <div key={axis} className={styles.axisControl}>
            <label className={styles.axisLabel}>{axis.toUpperCase()}:</label>
            <input 
              type="range" 
              min="-20" 
              max="20" 
              step="0.1" 
              value={(lightingOptions.directionalLightPosition as any)[axis]} 
              onChange={(e) => {
                const newPosition = new THREE.Vector3().copy(lightingOptions.directionalLightPosition);
                (newPosition as any)[axis] = parseFloat(e.target.value);
                updateLightingOption('directionalLightPosition', newPosition);
              }}
              className={styles.slider}
            />
            <span className={styles.value}>
              {(lightingOptions.directionalLightPosition as any)[axis].toFixed(1)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LightingSettings;