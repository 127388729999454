import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styles from './PartSelectionModal.module.css';
import { PCComponent } from '../../../types/types';
import { assetLoader } from '../../../services/assetLoader';

interface PartSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  parts: PCComponent[];
  onSelect: (component: PCComponent) => void;
  partType: string;
}

type SortOption = 'Date Posted (Newest)' | 'Date Posted (Oldest)' | 'Highest Rated' | 'Highest Price' | 'Lowest Price';

const PartSelectionModal: React.FC<PartSelectionModalProps> = ({
  isOpen,
  onClose,
  parts,
  onSelect,
  partType,
}) => {
  const [sortBy, setSortBy] = useState<SortOption>('Date Posted (Newest)');
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [modelAvailability, setModelAvailability] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const checkModelAvailability = async () => {
      const assetIds = parts.map(part => `${part.category}/${part.modelName}`);
      const availability = await assetLoader.checkAssetsAvailability(assetIds);
      setModelAvailability(
        Object.fromEntries(parts.map(part => [part.id, availability[`${part.category}/${part.modelName}`]]))
      );
    };
  
    if (isOpen) {
      checkModelAvailability();
    }
  }, [isOpen, parts]);
  
  const filterOptions = useMemo(() => {
    const options: Record<string, Set<string>> = {};
    parts.forEach(part => {
      Object.entries(part).forEach(([key, value]) => {
        if (typeof value === 'string' || typeof value === 'number') {
          if (!options[key]) options[key] = new Set();
          options[key].add(String(value));
        }
      });
    });
    return options;
  }, [parts]);

  // const filteredAndSortedParts = useMemo(() => {
  //   return parts
  //     .filter(part => 
  //       Object.entries(filters).every(([key, value]) => 
  //         value === '' || String(part[key as keyof PCComponent]) === value
  //       ) &&
  //       part.modelName.toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  //     .sort((a, b) => {
  //       switch (sortBy) {
  //         case 'Date Posted (Newest)':
  //           return (b.datePosted?.getTime() || 0) - (a.datePosted?.getTime() || 0);
  //         case 'Date Posted (Oldest)':
  //           return (a.datePosted?.getTime() || 0) - (b.datePosted?.getTime() || 0);
  //         case 'Highest Rated':
  //           return (b.rating || 0) - (a.rating || 0);
  //         case 'Highest Price':
  //           return (b.price || 0) - (a.price || 0);
  //         case 'Lowest Price':
  //           return (a.price || 0) - (b.price || 0);
  //         default:
  //           return 0;
  //       }
  //     });
  // }, [parts, sortBy, filters, searchTerm]);

  const filteredAndSortedParts = useMemo(() => {
    return parts.sort(() => 0);
  }, [parts]);

  const handleFilterChange = useCallback((key: string, value: string) => {
    setFilters(prev => ({...prev, [key]: value}));
  }, []);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h2 className={styles.modalTitle}>Select {partType}</h2>
        <div className={styles.modalContent}>
          <div className={styles.controls}>
            <input 
              type="text" 
              placeholder="Search components..." 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={styles.searchInput}
            />
            <div className={styles.sortControls}>
              <select 
                value={sortBy} 
                onChange={(e) => setSortBy(e.target.value as SortOption)}
                className={styles.sortSelect}
              >
                <option value="Date Posted (Newest)">Date Posted (Newest)</option>
                <option value="Date Posted (Oldest)">Date Posted (Oldest)</option>
                <option value="Highest Rated">Highest Rated</option>
                <option value="Highest Price">Highest Price</option>
                <option value="Lowest Price">Lowest Price</option>
              </select>
            </div>
          </div>
          <div className={styles.filters}>
            {Object.entries(filterOptions).map(([key, values]) => (
              <div key={key} className={styles.filterGroup}>
                <label>{key}</label>
                <select
                  value={filters[key] || ''}
                  onChange={(e) => handleFilterChange(key, e.target.value)}
                >
                  <option value="">All</option>
                  {Array.from(values).map(value => (
                    <option key={value} value={value}>{value}</option>
                  ))}
                </select>
              </div>
            ))}
          </div>
          <div className={styles.partsGrid}>
            {filteredAndSortedParts.map((part) => (
              <div 
                key={part.id} 
                className={`${styles.partCard} ${!modelAvailability[part.id] ? styles.unavailable : ''}`} 
                onClick={() => modelAvailability[part.id] && onSelect(part)}
              >
                <div className={styles.imageContainer}>
                  <img
                    src={`/images/${part.category}/${part.modelName}.png`}
                    onError={(e) => {
                      const img = e.currentTarget;
                      if (!img.src.endsWith('.jpg')) {
                        img.src = `/images/${part.category}/${part.modelName}.jpg`;
                      } else {
                        img.style.display = 'none';
                      }
                    }}
                    alt={part.modelName}
                    className={styles.partImage}
                    loading="lazy"
                    decoding="async"
                  />
                </div>
                {!modelAvailability[part.id] && (
                  <div className={styles.unavailableOverlay}>
                    <span>3D Model Unavailable</span>
                  </div>
                )}
                <div className={styles.partInfo}>
                  <h3 className={styles.partName}>{part.modelName}</h3>
                  <p className={styles.partCategory}>{part.category}</p>
                  <p className={styles.partPrice}>${(Math.random() * 490 + 10).toFixed(2).slice(0, -2) + '99'}</p>
                  <p className={styles.partRating}>Rating: {(Math.random() * 4 + 1).toFixed(1)}/5</p>
                  {/* {part.price && <p className={styles.partPrice}>${part.price.toFixed(2)}</p>}
                  {part.rating && <p className={styles.partRating}>Rating: {part.rating.toFixed(1)}/5</p>} */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className={styles.closeButton} onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default PartSelectionModal;