import React from 'react';
import PCBuilderScene from '../components/Scene/PCBuilderScene';

const Builder: React.FC = () => {
  return (
    <div>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">PC Builder</h1>
        <p>This is where users can build their PC configurations.</p>
      </div>
      <div className="App w-100vw h-screen overflow-hidden">
        <PCBuilderScene />
      </div>
    </div>
    
  );
};

export default Builder;