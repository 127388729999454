import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import UserActions from './UserActions';
import { Search } from 'lucide-react';

const Header: React.FC = () => {
    const [showSearch, setShowSearch] = useState(false);
  return (
    <header className="header">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-3">
          <Link to="/" className="flex items-center space-x-2 text-white">
            <img src="/favicon.ico" alt="PCLord logo" className="w-8 h-8" />
            <span className="text-xl font-bold">PCLord</span>
          </Link>
          <UserActions />
        </div>
        <div className="flex justify-between items-center py-2">
          <Navigation />
        {showSearch ? (
        <input
          type="text"
          placeholder="Search..."
          className="px-2 py-1 border rounded text-black"
          autoFocus
          onBlur={() => setShowSearch(false)}
        />
        ) : (
            <Search className="cursor-pointer text-white" onClick={() => setShowSearch(true)} />
        )}
        </div>
      </div>
    </header>
  );
};

export default Header;